<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Tudod, mire figyelj, ha híreket olvasol?
          </h2>
          <p class="hashText">- Az álhírek és a rágalmazás</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A végtelen információáramlás miatt az olvasóknak is egyre nehezebb dolguk van, amikor hiteles forrásokat keresnek. Vértezd fel magad és tudd meg, hogyan ismerheted fel az álhíreket!</p>

                <p class="text">
                Naponta több száz hír lát napvilágot különböző online felületeken. Már annyi kisebb hírportál és gyűjtőoldal létezik, hogy felsorolni is nehéz őket. S pont emiatt, a végtelen információáramlás miatt van az olvasóknak is egyre nehezebb dolguk, amikor hiteles forrásokat keresnek. Vértezd fel magad és tudd meg, hogy ismerheted fel az álhíreket!
                </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6">
              <img src="images/hirek.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
          <b-col cols="12">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                <b>Mit is nevezünk álhírnek?</b></p>

                <p class="text">
                Az álhírek (vagy fake news, hoax hírek) olyan szándékosan publikált átverések, melynek célja leginkább különböző eszmék terjesztése és a félrevezetés (dezinformáció). Arra játszanak, hogy erős érzelmi reakciót váltsanak ki belőlünk, ugyanis az érzelmek megnehezítik, hogy kritikusan gondolkodjunk, így akár anélkül megosztjuk az álhíreket másokkal, hogy egyetlen kétkedő hang megszólalna bennünk.</p>

                <p class="text">Többféle álhírrel találkozhatsz az egyszerűbb, elfogult tartalmaktól egészen a kattintásvadász, áltudományos hírekig. </p>
                <p class="text">
                <b>Hogyan ismerd fel?</b></p>
                <p class="text">Ahhoz, hogy felismerd az álhíreket, kritikusnak és körültekintőnek kell lenned. Mutatunk pár egyszerű lépést, ami segíthet leleplezni az átveréseket.
                </p>
            </div>
            </b-col>
            <b-col cols="12" lg="10" class="side-gap">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Ellenőrizd az információ forrását!</b><br>Ellenőrizd a hírportált, az újságíró nevét, az oldal hitelességét és az esetleges konfliktusokat az adott témával kapcsolatban. Számos áloldal létezik, ami egy már befutott portált másol le küllemében és az URL címben is maximum egy karterrel van több, vagy kevesebb. Ezek olyan apró részletek, amik joggal kongatják meg benned a vészharangot.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Vizsgáld meg a címet és a hírforrásokat!</b><br> Az álhírek gyakran félrevezető vagy túlzó címeket használnak, amelyek célja az olvasók figyelmének felkeltése vagy érzelmi reakciók kiváltása. Olvass el több forrást ugyanarról a témáról, és nézd meg, hogy más hírportálok is beszámolnak-e róla! Ha csak egyetlen forrásból származik a hír, az gyanús lehet.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Ellenőrizd a tartalmat!</b><br> Az álhírek gyakran hiányos vagy ellentmondásos információkat tartalmaznak. Az is előfordul, hogy “nevenincs” szakértőket szólaltatnak meg a tárgyalt kérdés kapcsán. Az NMHH azt javasolja, hogy mindenképp tedd fel magadnak az alábbi kérdéseket: Ki a cikk szerzője, és milyen témákról szokott írni? Relevánsak-e a cikkben megszólaló szakértők, az idézett források? Mikor történt az esemény, és mikor jelent meg a cikk? Mennyire megbízható az adott lap a témában?</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Gondolkodj kritikusan!</b><br> Kérdezd meg magadtól, hogy az adott hír vagy információ mennyire valószínű vagy reális! Ugyanez a kérdés már a cím esetében is érdekes. Ha túl hihetetlennek vagy túlzónak tűnik, bátran kutass kicsit a témában. Keress rá a kulcsszavakra olyan oldalakon, amelyek az információk ellenőrzésével, értelmezésével foglalkoznak! Angolul a Snopes.com, magyarul az Urbanlegends.hu segíthet.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Nézd meg jól a képeket és a videókat!</b><br> Vizsgáld meg, hogy az adott kép vagy videó korábban már megjelent-e más kontextusban, és használj olyan eszközöket, mint a Google Képkereső vagy az InVid, amelyek segítenek az álhíres képek vagy videók azonosításában!</p> 
                </li>
              </ul>
            </p>
            </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Tudod, mire figyelj, ha híreket olvasol?", 
        desc: "A végtelen információáramlás miatt az olvasóknak is egyre nehezebb dolguk van, amikor hiteles forrásokat keresnek. Vértezd fel magad és tudd meg, hogyan ismerheted fel az álhíreket!", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Tudod, mire figyelj, ha híreket olvasol?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A végtelen információáramlás miatt az olvasóknak is egyre nehezebb dolguk van, amikor hiteles forrásokat keresnek. Vértezd fel magad és tudd meg, hogyan ismerheted fel az álhíreket!"        
    })
  }
}
</script>